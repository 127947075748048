<template>
  <div class="star-rating">
    <div>
      <v-icon
        v-for="index in 5"
        :key="index"
        :color="index <= rating ? 'yellow' : 'grey lighten-1'"
        >mdi-star</v-icon
      >
    </div>

    <span class="reviews">({{ reviewsCount }} avaliações)</span>
  </div>
</template>

<script>
export default {
  name: "StarRating",
  props: {
    rating: {
      type: Number,
      required: true,
    },
    reviewsCount: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style scoped>
.star-rating {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
}
</style>
