<template>
  <div>
    <nav>
      <div id="logo" @click="goToHome">
        <BlueLogoWithName style="max-width: 100px; cursor: pointer" />
      </div>
      <div id="menu">
        <a href="https://appbio.com.br">Home</a>
        <a href="#" class="active">Encontrar terapeutas</a>
        <a href="https://appbio.com.br/sou-terapeuta">Sou terapeuta</a>
        <a @click="redirectToLogin">Entrar</a>
        <button @click="redirectToRegister">Criar conta</button>
      </div>
    </nav>
    <section v-if="showSearch" id="search-section">
      <span class="search-title">Encontre um biomagnetista</span>
      <p class="search-subtitle">
        Encontre terapeutas formados no mais alto nível de excelência
      </p>
      <div id="search-wrapper" class="search-wrapper">
        <div id="search-box">
          <input
            v-model="searchWord"
            type="text"
            placeholder="Pesquise por nome ou motivo"
          />
          <button @click="onSearch">Pesquisar</button>
        </div>
        <Dropdown
          :items="locations"
          label-key="name"
          value-key="name"
          @item-selected="handleLocationSelected"
        />
      </div>
    </section>
  </div>
</template>

<script>
import Dropdown from "@/domains/find-therapist/drop-down/Dropdown.vue";
import BlueLogoWithName from "@/components/svg/BlueLogoWithName";
import { findAllCities } from "@/services/external/cities-service";

export default {
  name: "Navbar",
  components: {
    Dropdown,
    BlueLogoWithName,
  },
  props: {
    showSearch: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    selectedLocation: null,
    locations: [],
    searchWord: "",
  }),
  created() {
    this.getCities();
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    async getCities() {
      const result = await findAllCities();
      this.locations = result.data;
      this.locations.sort((a, b) => a.name.localeCompare(b.name));
    },
    handleLocationSelected(selectedValue) {
      this.selectedLocation = selectedValue;
      this.onSearch();
    },
    onSearch() {
      this.$emit("search", {
        searchWord: this.searchWord,
        location: this.selectedLocation,
      });
    },
    redirectToRegister() {
      this.$router.push({
        path: "register",
      });
    },
    redirectToLogin() {
      this.$router.push({
        path: "login",
      });
    },
    goToHome() {
      window.location.href = "https://appbio.com.br";
    },
    handleScroll() {
      const searchSection = document.getElementById("search-section");
      const searchWrapper = document.getElementById("search-wrapper");
      const stickyThreshold = searchWrapper.offsetTop - 12;
      const searchWrapperTop = searchWrapper.offsetTop - 12;

      // Adding a transition effect for smoothness
      searchSection.style.transition = "top 0.3s, padding 0.3s";

      if (window.pageYOffset > stickyThreshold) {
        searchSection.style.position = "fixed";
        searchSection.style.top = `-${searchWrapperTop}px`;
        searchSection.style.zIndex = 100;
        searchSection.style.width = "100%";
        searchSection.style.padding = "16px";
      } else {
        searchSection.style.position = "";
        searchSection.style.top = "";
        searchSection.style.zIndex = "";
        searchSection.style.width = "";
        searchSection.style.padding = "";
      }
    },
  },
};
</script>

<style scoped>
nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 4rem;
  background-color: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  flex-wrap: wrap;
}

#logo {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
}

#menu {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

#menu a {
  text-decoration: none;
  color: #333;
  font-weight: 600;
  padding: 0.5rem 1rem;
  transition: background-color 0.3s;
  cursor: pointer;
}

#menu a.active {
  color: #265acc;
}

#menu a:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

#menu button {
  background-color: #265acc;
  color: #ffffff;
  border: none;
  padding: 0.5rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

#menu button:hover {
  background-color: #0056b3;
}

/* Search section styles */
#search-section {
  background-color: #265acc;
  padding: 3rem 2rem;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#search-section .search-title {
  font-size: 2rem;
  font-weight: 900;
}
#search-section .search-subtitle {
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 2rem;
  align-self: center;
}
#search-box {
  flex: 1;
  display: flex;
  position: relative;
  background-color: #ffffff;
  border-radius: 12px;
  padding: 10px 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  min-width: 600px;
}

#search-box input {
  flex: 1;
  padding: 0.5rem;
  padding-right: 3rem;
  border: none;
  border-radius: 12px;
  outline: none;
  font-size: 1rem;
}

#search-box button {
  position: absolute;
  right: 20px;
  background-color: #265acc;
  color: #ffffff;
  border: none;
  padding: 0.5rem 1.5rem;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-weight: bold;
}

#search-box button:hover {
  background-color: #0056b3;
}

.search-wrapper {
  display: flex;
  gap: 20px;
  align-items: center;
}

/* Responsive styles */
@media (max-width: 768px) {
  nav {
    flex-direction: column;
    align-items: flex-start;
  }

  #menu {
    flex-direction: column;
    gap: 10px;
    padding-top: 1rem;
    width: 100%;
  }

  #search-box {
    min-width: auto;
    width: 100%;
  }

  .search-wrapper {
    flex-direction: column-reverse;
    align-items: flex-start;
    width: 100%;
  }
  .search-wrapper > * {
    width: 100%;
  }
}

@media (max-width: 480px) {
  nav {
    padding: 1rem;
  }

  #menu a,
  #menu button {
    padding: 0.5rem;
  }
}
</style>
