<template>
  <div>
    <div class="dropdown-container" @click="toggleDropdown">
      <div class="selected-item">
        <v-icon class="icon">mdi-map-marker</v-icon>

        <div class="selected-item-wrapper">
          <span class="label">Localização</span>
          <input
            ref="inputsearch"
            v-model="searchQuery"
            type="text"
            class="value"
            placeholder="Todas"
            @input="filterItems"
            @click.stop
          />
        </div>

        <v-icon class="arrow" @click="focusInput">mdi-chevron-down</v-icon>
      </div>
      <div v-if="isOpen" class="dropdown-items">
        <div v-if="filteredItems.length === 0" class="dropdown-item">
          Nenhum resultado
        </div>
        <div
          v-for="item in filteredItems"
          :key="item[valueKey]"
          class="dropdown-item"
          @click="selectItem(item, $event)"
        >
          {{ item[labelKey] }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DropDown",
  props: {
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
    labelKey: {
      type: String,
      required: true,
      default: "label",
    },
    valueKey: {
      type: String,
      required: true,
      default: "value",
    },
  },
  data() {
    return {
      isOpen: false,
      searchQuery: "",
      selectedItem: "Todas",
      filteredItems: [],
    };
  },
  created() {
    setTimeout(() => {
      this.filteredItems = this.items;
    }, 1000);
  },
  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen;
      if (this.isOpen) {
        document.addEventListener("click", this.handleClickOutside);
      } else {
        document.removeEventListener("click", this.handleClickOutside);
      }
    },
    filterItems() {
      this.toggleDropdown();
      this.filteredItems = this.items.filter((item) =>
        item[this.labelKey]
          .toLowerCase()
          .includes(this.searchQuery.toLowerCase())
      );
      console.log(this.filteredItems);
      if (!this.searchQuery || this.searchQuery === "") {
        this.$emit("item-selected", null);
      }
    },
    selectItem(item, event) {
      event.stopPropagation();
      if (item === null) {
        this.selectedItem = null;
        this.isOpen = false;
        this.$emit("item-selected", null);
        return;
      }
      this.selectedItem = item[this.valueKey];
      this.searchQuery = item[this.labelKey]; // Update search query with selected item
      this.isOpen = false;
      this.$emit("item-selected", item[this.valueKey]);
    },
    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.isOpen = false;
        document.removeEventListener("click", this.handleClickOutside);
      }
    },
    focusInput() {
      this.$refs.inputsearch.focus();
    },
  },
};
</script>

<!-- Add your existing style here -->

<style scoped>
.dropdown-container {
  position: relative;
  width: 200px;
  border: 1px solid #d4d4d4;
  border-radius: 12px;
  padding: 10px 15px;
  cursor: pointer;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.selected-item {
  display: flex;
  align-items: center;
  color: darkgray;
}

.selected-item-wrapper .value {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100px;
  border: none;
  outline: none;
}
.selected-item-wrapper .value:focus {
  border: none;
}

.icon {
  margin-right: 10px;
}

.arrow {
  margin-left: auto;
}

.dropdown-items {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border: 1px solid #d4d4d4;
  border-radius: 10px;
  margin-top: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: white;
  z-index: 1000;
  max-height: 200px; /* Set a maximum height */
  overflow-y: auto; /* Enable vertical scrolling */
}

.dropdown-item {
  padding: 10px 15px;
  cursor: pointer;
  color: darkgray;
  border-radius: 10px;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}

.selected-item-wrapper {
  display: flex;
  flex-direction: column;
}

.label {
  font-size: 11px;
  color: #265acc;
}

@media (max-width: 768px) {
  .dropdown-container {
    width: 100%;
  }
}
</style>
